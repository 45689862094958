import React from 'react'
import { Heading } from '@moonpig/launchpad-components'
import { Page } from '@moonpig/web-core-app'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { setBrowserCookie } from '@moonpig/web-core-cookies'
import { Routes } from '@moonpig/web-core-routing'
import { Store } from '@moonpig/web-core-types'
import Head from 'next/head'

import { HEADING_TEXT, REST_OF_THE_WORLD_TEXT, TITLE_TEXT } from './constants'
import {
  StyledBackground,
  StyledContainer,
  StyledLink,
  StyledLogo,
  StyledSecondaryButton,
} from './CountrySelectionPageStyles'

export const STORE_COOKIE_NAME = 'mnpg-store'
export const STORE_COOKIE_MAX_AGE_30_DAYS = 2592000

const storeIdToPath = (id: string) => `/${id}/`

export const CountrySelectionPage: Page<
  { stores: Store[]; hostUrl: string; asPath: string | undefined },
  Routes['home']
> = ({ stores, hostUrl, asPath }) => {
  const handleClick = (storeId: string, text: string) => {
    trackGAEvent({
      event: 'select_content',
      event_data: {
        category: 'site country selection',
        action: text,
        label: 'save selection',
        value: undefined,
      },
    })
    setBrowserCookie(STORE_COOKIE_NAME, storeId.toUpperCase(), {
      maxAge: STORE_COOKIE_MAX_AGE_30_DAYS,
    })
  }

  const restOfWorldStore = stores[0]

  return (
    <StyledBackground>
      <Head>
        <title>Country Selection | Moonpig</title>
        <meta name="title" content="Country Selection" />
        <meta
          name="description"
          content="Please select which country you want to send your order to"
        />
        <link rel="canonical" href="https://www.moonpig.com/" />
      </Head>
      <StyledContainer>
        <StyledLink title={TITLE_TEXT} href={'/uk/'}>
          <StyledLogo aria-hidden />
        </StyledLink>
        <Heading
          level="h2"
          textAlign="center"
          typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay04' }}
        >
          {HEADING_TEXT}
        </Heading>
        {stores.map(({ id, name }) => (
          <StyledSecondaryButton
            key={id}
            onClick={() => handleClick(id, name)}
            href={createStoreHomepageUrl(id, hostUrl, asPath)}
          >
            {name}
          </StyledSecondaryButton>
        ))}
        <StyledSecondaryButton
          onClick={() =>
            handleClick(restOfWorldStore.id, REST_OF_THE_WORLD_TEXT)
          }
          href={createStoreHomepageUrl(restOfWorldStore.id, hostUrl, asPath)}
        >
          {REST_OF_THE_WORLD_TEXT}
        </StyledSecondaryButton>
      </StyledContainer>
    </StyledBackground>
  )
}

CountrySelectionPage.layout = 'none'

const createStoreHomepageUrl = (
  storeId: string,
  hostUrl: string,
  asPath?: string,
) => {
  const url = new URL(`${hostUrl}${asPath || /* istanbul ignore next */ ''}`)
  return `${storeIdToPath(storeId)}${url.search}`
}

CountrySelectionPage.getInitialProps = async ({
  getCookie,
  stores,
  asPath,
  environment,
}) => {
  if (stores.length === 1) {
    const location = createStoreHomepageUrl(
      stores[0].id,
      environment.hostUrl,
      asPath,
    )

    return {
      pageRedirect: {
        location,
        statusCode: 302,
      },
    }
  }

  const cookieStore = getCookie(STORE_COOKIE_NAME) || ''

  const matchStore = cookieStore
    ? stores.find(store => {
        return store.id === cookieStore.toLowerCase()
      })
    : null

  if (matchStore) {
    const location = createStoreHomepageUrl(
      matchStore.id,
      environment.hostUrl,
      asPath,
    )

    return {
      pageRedirect: { location, statusCode: 302 },
    }
  }

  return {
    stores,
    hostUrl: environment.hostUrl,
    asPath,
  }
}
